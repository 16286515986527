// 404.js
import { useRouter } from 'next/router'
import { MdArrowBack as ArrowBack } from 'react-icons/md'
import Image from 'next/image'
import { NextSeo } from 'next-seo'
import { FC } from 'react'
import Layout from '../components/layout'
import Container from '../components/container'
import Intro from '../components/intro'
import { getContactData, getMenuItems, getSiteData } from '../lib/api'
import Footer from '../components/footer'
import TextBox from '../components/text-box'
import Button from '../components/button'
import {
  baseLanguageURLs,
  useCurrentLocale,
  useTranslation,
} from '../lib/utils'
import { allMenuItemsType, countriesType, SiteDataProps } from '../lib/types'

interface FourOhFourProps {
  allMenuItems: allMenuItemsType
  siteData: SiteDataProps
}
const FourOhFour: FC<FourOhFourProps> = ({ allMenuItems, siteData }) => {
  const router = useRouter()
  const locale = useCurrentLocale()
  const t = useTranslation()
  return (
    <Layout>
      <NextSeo
        title={t.fourohfourHeading}
        description={t.fourohfourParagraph}
        canonical={`${baseLanguageURLs(locale)}404`}
        languageAlternates={process.env.LOCALES?.split(',').map((language) => ({
          hrefLang: language,
          href: `${baseLanguageURLs(language as countriesType)}404`,
        }))}
        noindex
      />
      <header className="bg-blue dark:bg-zinc-900">
        <Container className="pt-8">
          <Intro allMenuItems={allMenuItems} />
        </Container>
      </header>
      <main>
        <section className="relative mt-[-1px] bg-blue pt-8 focus:outline-0 dark:bg-zinc-900 lg:pt-8 xl:min-h-[80vh] xl:pb-24">
          <Container>
            <TextBox
              className="lg:!max-w-screen-lg"
              color="white"
              orientation="right"
            >
              <h1 className="text-4xl font-semibold leading-tight focus:outline-0 focus-visible:outline focus-visible:outline-8 focus-visible:outline-offset-4 focus-visible:outline-darkblue dark:focus-visible:outline-white lg:text-6xl">
                {t.fourohfourHeading}
              </h1>
              <p className="pb-8">{t.fourohfourParagraph}</p>
              <Button
                className="!margin-0 absolute bottom-0 left-[50%] translate-x-[-50%] translate-y-[50%] items-center md:left-auto md:left-[48px] md:translate-x-0"
                href={locale === router.defaultLocale ? '/' : `/${locale}`}
              >
                <ArrowBack className="mr-2 inline-flex text-2xl" />
                {t.fourohfourCTA}
              </Button>
            </TextBox>
          </Container>
          <div className="right-0 bottom-0 w-full xl:absolute xl:w-[50%]">
            <Image
              alt={t.fourohfourAlt}
              src="/Tillganglighet.png"
              className="z-10 object-contain object-bottom dark:brightness-75"
              width={1158}
              height={1059}
              priority
            />
          </div>
        </section>
      </main>
      <Footer siteData={siteData} />
    </Layout>
  )
}

export async function getStaticProps({ locale }: { locale: countriesType }) {
  const siteData = await getSiteData(false)
  const contactData = await getContactData(
    false,
    locale !== 'sv' ? locale : undefined
  )
  const allMenuItems = await getMenuItems(false, locale)

  return {
    props: {
      siteData,
      allMenuItems,
      contactData,
      preview: false,
    },
    revalidate: 1,
  }
}

export default FourOhFour
